import { withSSRContext } from "aws-amplify";
//import type { NextPage, GetServerSideProps, InferGetServerSidePropsType } from "next";
import type { NextPage, GetStaticProps, InferGetStaticPropsType } from "next";
import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';
import { toast } from "react-toastify";

import { CollectionData } from "data/collections";
import { getFooterData, FooterData } from "data/footer";
import { getJoinStudioData, JoinStudioData } from "data/footer/joinStudio";
import { ProductData } from "data/product/products";

import { getProductCollectionWithSlug } from "~/api/collectionsApi";
import { getMostPopularProducts, getNewReleasesProducts } from "~/api/productApi";
import { BrowseSSGData } from "~/components/browse/types";

import { HomepageData, getHomepageData } from "../../data/homepage";
import { Homepage } from "../components/homepage/Homepage";
import { handleSocialAuthRedirect } from '~/api/authApi';
import { useAuth } from '~/hooks/auth/useAuth';
import { getStateFromStorage } from "~/util/storageUtils";

interface StaticProps {
  homepageData: HomepageData;
  joinStudioData: JoinStudioData;
  footerData: FooterData;
  collectionData: CollectionData;
  browseData: BrowseSSGData;
}

const Home: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  homepageData,
  joinStudioData,
  footerData,
  collectionData,
  browseData,
}) => {
  const router = useRouter();
  const { code } = router.query;
  const { user, loadingCurrentUser } = useAuth();
  const [isProcessingAuth, setIsProcessingAuth] = useState(false);
  const [authProcessed, setAuthProcessed] = useState(false);

  // Handle Cognito callback
  useEffect(() => {
    // Direct access to URL parameters as a fallback
    const urlParams = new URLSearchParams(window.location.search);
    const urlCode = urlParams.get('code');

    console.log("URL params method - code:", urlCode);
    console.log("Router method - isReady:", router.isReady, "code:", code);

    // Use either the router code or direct URL code
    const authCode = code || urlCode;

    console.log("authCode", authCode);
    console.log("isProcessingAuth", isProcessingAuth);
    console.log("authProcessed", authProcessed);
    console.log("user", user);
    console.log("loadingCurrentUser", loadingCurrentUser);

    if (authCode && !isProcessingAuth && !authProcessed && (!user || loadingCurrentUser)) {
      console.log("Detected Cognito callback with code:", authCode);
      setIsProcessingAuth(true);

      // Check if we have stored onboarding state using storage utils
      const onboardingState = getStateFromStorage<{
        isOnboarding?: boolean;
        storefrontSlug?: string;
        appSlug?: string;
        returnPath?: string;
      }>('onboardingAuthState');
      if (onboardingState) {
        console.log("Retrieved onboarding state in homepage:", onboardingState);
      }

      // Remove the code parameter from the URL to prevent infinite loops
      const newUrl = window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);

      // Try to get the current authenticated user
      Auth.currentAuthenticatedUser()
        .then(async (user) => {
          console.log("Successfully authenticated user:", user);

          // Process the redirect with our backend
          try {
            // Pass storefront and app slugs from onboarding state if available
            const storefrontSlug = onboardingState?.storefrontSlug;
            const appSlug = onboardingState?.appSlug;

            const result = await handleSocialAuthRedirect(storefrontSlug, appSlug);
            console.log("Social auth redirect processed:", result);

            if (result.type === "success") {
              console.log("Successfully created/linked user in backend:", result.value);

              // Check if we should redirect back to onboarding
              if (result.metadata?.returnToOnboarding && result.metadata.returnPath) {
                console.log('Redirecting back to onboarding flow from homepage:', result.metadata.returnPath);
                router.push(result.metadata.returnPath);
                return;
              }

              //toast.success("Successfully signed in with Google");
            } else {
              console.error("Error processing social auth redirect:", result.error);
              toast.error(result.message || "Failed to get authenticated. It's likely you have an old account with the same email. Please log in with your old credentials or contact support.");

              // Force sign out on error to allow retrying
              try {
                await Auth.signOut();
              } catch (e) {
                console.error("Error signing out after failed auth:", e);
              }
            }
          } catch (err) {
            console.error("Error handling social auth redirect:", err);
            toast.error("Authentication error. Please try again later.");

            // Force sign out on error to allow retrying
            try {
              await Auth.signOut();
            } catch (e) {
              console.error("Error signing out after failed auth:", e);
            }
          } finally {
            setIsProcessingAuth(false);
            setAuthProcessed(true); // Mark that we've processed this auth attempt
          }
        })
        .catch((err) => {
          console.error("Error getting authenticated user:", err);
          toast.error("Authentication error. Please try again.");
          setIsProcessingAuth(false);
          setAuthProcessed(true); // Mark that we've processed this auth attempt
        });
    }
  }, [router.isReady, code, isProcessingAuth, authProcessed, user, loadingCurrentUser, router]);

  return (
    <Homepage
      homepageData={homepageData}
      joinStudioData={joinStudioData}
      browseData={browseData}
      footerData={footerData}
      collectionData={collectionData}
    />
  );
};

export const getStaticProps: GetStaticProps<StaticProps> = async () => {
  // TODO: we should use Next middleware for this redirect check, so it can run on edge nodes
  // for much better performance.
  // see:
  // https://nextjs.org/docs/advanced-features/middleware
  // netlify support - https://www.netlify.com/blog/next.js-middleware-on-netlify/
  // Amplify issues out of the box - https://github.com/aws-amplify/amplify-js/issues/9145 (Cognito is trash - no surprise there)
  // but likely fixable with a custom check of JWT: https://github.com/aws-amplify/amplify-js/issues/9145#issuecomment-964338047
  //const { Auth } = withSSRContext(context);
  const collectionSlug = "all-music";
  const newReleases = await getNewReleasesProducts(true, 6);
  const popularProducts = await getMostPopularProducts(true, 24);
  const popularProductsProducts: ProductData[] = popularProducts.products;
  const collectionDataObj: CollectionData = await getProductCollectionWithSlug(collectionSlug);
  const newReleasesTotalAvailableCount = newReleases.totalAvailableCount;
  //const newReleasesProducts: ProductData[] = [(collectionDataObj as ProductData), ...newReleases.products.splice(0, 5)];
  const newReleasesProducts: ProductData[] = [...newReleases.products.splice(0, 6)];
  const creatorProfileImages: string[] = collectionDataObj?.productsInfoIncluded.reduce(
    (r: string[], e: ProductData) => (r.push(e.creatorProfileImageURL), r),
    [],
  );
  const collectionData: CollectionData = {
    ...collectionDataObj,
    creatorProfileImages: creatorProfileImages,
  };

  try {
    return {
      props: {
        homepageData: getHomepageData(),
        joinStudioData: getJoinStudioData(),
        footerData: getFooterData(),
        collectionData: collectionData,
        browseData: {
          newReleases: newReleasesProducts,
          newReleasesTotalAvailableCount: newReleasesTotalAvailableCount,
          popularProducts: popularProductsProducts,
        },
      },
    };
  } catch (err) {
    return {
      props: {
        isAuthenticated: false,
        homepageData: getHomepageData(),
        joinStudioData: getJoinStudioData(),
        footerData: getFooterData(),
        collectionData: collectionData,
        browseData: {
          newReleases: newReleasesProducts,
          newReleasesTotalAvailableCount: newReleasesTotalAvailableCount,
          popularProducts: popularProductsProducts,
        },
      },
    };
  }
};

export default Home;
